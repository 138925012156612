.navbar {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff !important;
  padding: 0 20px !important;
  border-bottom: 1px solid #d3d3d3;
  position: fixed;
  top: 0;
  z-index: 1000;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  height: 50px !important;
  width: 100%;
  color: #007bff;
}

/* Navbar brand */
.nav-brand {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000000;
  border-right: 1px solid #d3d3d3;
  padding-right: 1rem;
}

.navbar-brand a {
  font-size: 1.5rem;
  color: inherit;
  text-decoration: none;
}

.navbar-right {
  display: flex;
  align-items: center;
}

/* .search-bar input {
    padding: 0.5rem;
    margin-right: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .search-input {
    width: 100%;
    max-width: 100px;
    padding: 0.4rem 0.8rem;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    font-size: 0.9rem;
  } */

/* Profile section */
.profile-dropdown {
  position: relative;
}

.profile-icon {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.profile-popup {
  display: none;
  position: absolute;
  top: 2rem;
  right: 0;
  /* background-color: var(--popup-bg); */
  color: var(--popup-text);
  background-color: rgba(255, 255, 255, 0.789);
  padding: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 300px;
}

.dropdown-divider {
  border-bottom: 0.01em;
  border-color: rgba(184, 184, 184, 0.426);
  border-style: solid;
}

.profile-dropdown:hover .profile-popup {
  display: block;
}

/* Profile details inside popup */
.profile-details {
  margin-bottom: 0.5rem;
}

.profile-details p {
  margin: 0.3rem 0;
  font-size: 0.9rem;
  color: #333;
}

/* Logout button */
.logout-button {
  width: 100%;
  padding: 0.4rem;
  background-color: #ff6b6b;
  color: #ffffff;
  border: none;
  border-radius: 0px !important;
  cursor: pointer;
  font-size: 0.9rem;
}

.logout-button:hover {
  background-color: #ff4a4a;
}

@media (max-width: 768px) {
  .nav-search {
    display: none;
  }

  .nav-brand {
    font-size: 1.2rem;
    padding-right: 0.5rem;
  }

  /* .profile-popup {
      width: 100%;
      right: auto;
    } */
}