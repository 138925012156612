.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  /* Optional: background color */
}

.login-container {
  position: relative;
  /* Ensure stacking context for the login container */
  flex: 0 0 400px;
  max-width: 400px;
  padding: 25px;
  background: linear-gradient(106deg, #4c95c9 45%, #d5ff6c 98%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #ffffff;
  border-radius: 5px;
  z-index: 2;
}

.login-page {
  background: linear-gradient(#ffffff, #91d3ff);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: stretch;
  /* Ensure children stretch to full height */
  padding: 35px;
}

.login-video {
  position: relative;
  /* Make video container positioned */
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 5px 0 0 5px;
  z-index: 1;
  /* Ensure it's above the default stacking context */
}

.login-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid rgba(0, 179, 255, 0.357);
  opacity: 0.8;
  /* Add opacity for the overlapping area */
}

.login-container h3 {
  text-align: center;
  margin-bottom: 30px;
  color: rgb(243, 255, 209);
  padding: 10px;
  border: 1px dashed rgba(255, 255, 255, 0.357);
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.455);
  border-radius: 5px;
  background: linear-gradient(to left, #4ea8c6, #6db2ca);
}

.error {
  color: red;
  margin-bottom: 10px;
}

.login-container .form-control {
  background: linear-gradient(108deg, #c9dfff4d 20%, transparent 70%);
}

.login-container a {
  color: rgb(2, 62, 147);
}

.login-container .btn-primary {
  color: #fff;
  background-color: #0072ff;
  border-color: #0072ff;
  line-height: 30px;
}

.login-container .btn-primary:hover {
  color: #fff !important;
  background-color: #020350 !important;
  border-color: #ffffff !important;
}

.login-container .form-check-label {
  color: #020350;
  cursor: pointer;
}

.login-container .form-check-input {
  --bs-form-check-bg: transparent;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 1.5x;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #0072ff;
  print-color-adjust: exact;
}

/* Media Queries for Smaller Screens */
@media (max-width: 768px) {
  .login-page {
    flex-direction: column;
    /* Stack video and login container vertically */
    align-items: center;
    /* Center items vertically */
    padding: 10px;
  }

  .login-video {
    width: 100%;
    /* Take full width */
    height: 50%;
    /* Adjust height for the video */
    border-radius: 10px 10px 0 0;
    /* Rounded corners for the top */
  }

  .login-video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .login-container {
    width: 100%;
    /* Take full width */
    max-width: none;
    /* border-radius: 0 0 10px 10px;  */
    /* Rounded corners for the bottom */
    padding: 15px;
    /* background: linear-gradient(140deg, #5aafcb 45%, #4193af 0); */
  }

  .login-container {
    margin-left: 0;
    /* Remove overlap on smaller screens */
    border-radius: 0;
  }
}

@media (max-width: 480px) {
  .login-container {
    padding: 10px;
  }

  .login-container h2 {
    font-size: 1.5rem;
    /* Smaller heading for smaller screens */
  }

  .btn-primary {
    font-size: 0.9rem;
    /* Smaller button text */
    padding: 8px 10px;
  }
}