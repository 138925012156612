/* Container styling */
.container-report-tree {
  background-color: #f9f9f9;
  /* border: 1px solid #ddd;
  border-radius: 6px; */
  padding: 10px;
  max-width: 600px;
  margin: auto;
}

/* Category headers */
.container-report-tree h5 {
  color: #333;
  font-weight: bold;
  margin-bottom: 8px;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 4px;
}

/* Node list */
.container-report-tree ul {
  list-style: none;
  padding-left: 10px;
  /* Indent child nodes */
  margin-bottom: 16px;
}

/* Tree node styling */
.container-report-tree li {
  position: relative;
  padding: 4px 8px;
  margin: 4px 0;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: all 0.3s ease;
}

/* Cursor and hover effect */
.container-report-tree li:hover {
  cursor: pointer;
  background-color: #f1f1f1;
  border-color: #bbb;
}

/* Hidden input */
.container-report-tree input[type="hidden"] {
  display: none;
}

/* Child node connector (optional, for visual hierarchy) */
.container-report-tree li::before {
  content: '';
  position: absolute;
  left: -12px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 2px;
  background-color: #ddd;
}

/* Responsive styling */

/* For small screens */
@media (max-width: 576px) {
  .container-report-tree {
    max-width: 100%;
    padding: 8px;
  }

  .container-report-tree h5 {
    font-size: 1rem;
    margin-bottom: 6px;
  }

  .container-report-tree li {
    font-size: 0.9rem;
    padding: 3px 6px;
    margin: 3px 0;
  }

  .container-report-tree ul {
    padding-left: 8px;
  }
}

/* For medium screens */
@media (max-width: 768px) and (min-width: 577px) {
  .container-report-tree {
    max-width: 90%;
    padding: 10px;
  }

  .container-report-tree h5 {
    font-size: 1.1rem;
  }

  .container-report-tree li {
    font-size: 1rem;
    padding: 4px 8px;
  }

  .container-report-tree ul {
    padding-left: 10px;
  }
}