/* Sidebar Container */
.sidebar {
  /* width: 250px; */
  /* height: 100vh; */
  /* background-color: #2c3e50; */
  /* background-color: #2c2b32; */
  /* background: linear-gradient(to left, #075262, #268688); */
  background: linear-gradient(110deg, #2b6d9d 45%, #06516f 120%);
  color: #f1fdff !important;
  position: fixed;
  /* Sticks to the left */
  top: 45px;
  left: 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  height: calc(100vh - 45px);
  display: flex;
}

/* Sidebar will fill the space between navbar and footer */
.sidebar {
  flex-grow: 1;
  overflow-y: auto;
  padding: 6px 10px !important;
}

.sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.sidebar ul li {
  position: relative;
  margin: 0;
  padding: 0;
}

.sidebar ul li i {
  padding-right: 10px;
}

.sidebar ul li .menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 15px;
  border-radius: 2px;
  font-weight: 400;
  margin-bottom: 4px;
}

/* Active state styling */
.sidebar ul li .menu-item.active {
  /* background-color: #ebebeb; */
  background: linear-gradient(#ffffff, #c2f1ff);
  color: #00375f;
  /* font-weight: 500; */
}

/* Hover state styling */
.sidebar ul li .menu-item:hover {
  /* background-color: #ebebeb; */
  background: linear-gradient(#ffffff, #c2f1ff);
  color: #00375f;
  /* font-weight: 500; */
}

/* Arrow styling when expanded */
.sidebar ul li .menu-item .arrow.open {
  transform: rotate(180deg);
}

/* Optional: Add more styling for expanded state */
.sidebar ul li .menu-item .arrow {
  cursor: pointer;
}

.sidebar ul li .menu-item a {
  flex: 1;
  /* Ensure the link occupies full space */
  text-decoration: none;
  color: inherit;
}

.sidebar ul li .menu-item .arrow {
  font-size: 10px;
  margin-left: 10px;
  cursor: pointer;
  transition: transform 0.3s;
}

.sidebar ul li .menu-item .arrow.open {
  transform: rotate(180deg);
  /* Rotate arrow when open */
}

/* Submenu */
.sidebar ul ul {
  margin: 0;
  padding-left: 5px;
  /* Indentation for submenus */
  padding-right: 2px;
  display: block;
}

.sidebar ul ul li .menu-item {
  padding-left: 40px;
  /* Extra indentation for submenu items */
}

.sidebar ul ul li a {
  font-size: 14px;
  color: #bdc3c7;
}

.sidebar ul li:hover>ul {
  display: block;
  /* Show submenu on hover */
}

/* Scrollbar Styling */
.sidebar::-webkit-scrollbar {
  width: 4px;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 5px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #555555;
}

.sidebar::-webkit-scrollbar-track {
  background: linear-gradient(#2b939b, #0a7374);
}

.arrow {
  /* Increase font size */
  margin-left: 8px;
  cursor: pointer;
  color: #48d1d1;
}

.arrow.open {
  color: #00beb8;
  /* Highlight when expanded */
}