.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  /* Smoothly rounded corners */
  width: 90%;
  /* Set a default width for small screens */
  max-width: 500px;
  /* Limit the width for larger screens */
  max-height: 80%;
  /* Allow scrolling for long content */
  overflow-y: auto;
  /* Enable scrolling for overflow content */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
  position: relative;
  transition: transform 0.3s ease-in-out;
  /* Smooth transition for opening */
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  /* Make the close button visible */
  cursor: pointer;
}

@media (min-width: 768px) {
  .modal-content {
    width: 70%;
    /* Adjust width for medium screens */
  }
}

@media (min-width: 1024px) {
  .modal-content {
    width: 50%;
    /* Further reduce width for larger screens */
  }
}