.container-head {
  display: flex;
  justify-content: center;
  /* border-bottom: 1px solid #c4c4c4; */
  padding: 4px 10px;
}

.container-head-details {
  padding: 10px;
  border: 1px solid #dddddd;
}

.form-group {
  margin-top: 3px;
}

label {
  font-size: 14px;
}

.form-control,
.form-select {
  margin-top: 2px;
  height: 34px;
  font-size: 14px;
  /* line-height: 20px; */
}

.container-input-field-details {
  padding: 10px;
}

.container-grid-details {
  height: 250px;
  overflow: auto;
  padding: 5px;
  border: 1px solid rgb(220, 220, 220);
}


.table-container {
  overflow-x: auto;
  /* Ensures horizontal scrolling if the table overflows */
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
  background-color: #f9f9f9;
}

.table-bordered {
  border: 1px solid #ddd;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #ddd;
  padding: 8px;
  word-wrap: break-word;
  /* Wraps long column names within the cell */
  white-space: normal;
  /* Allows multi-line text in cells */
}

.table-bordered th {
  background-color: #007bff;
  /* Blue background for the header */
  color: #fff;
  /* White text color */
  font-weight: bold;
  text-align: center;
  font-size: 12px;
  /* Center aligns column headers */
}

.table-bordered tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table-bordered tr:hover {
  background-color: #f1f1f1;
}

th,
td {
  text-align: center;
  /* Align all table cells to the center */
  vertical-align: middle;
}

.table-container .table {
  min-width: 100%;
}

/* Optional: Add padding for better readability */
.table-bordered td {
  padding: 5px;
  font-size: 12px;
}

/* Styling for a hovered row */
.table-bordered tbody tr:hover td {
  background-color: #e9ecef;
  /* cursor: pointer; */
}

.container-button-details {
  margin-top: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.container-footer-input-field-details {
  padding: 5px;
  /* border: 1px solid rgb(219, 219, 219); */
}

.btn {
  font-size: 12px;
}

.container-details-report-columns {
  padding: 10px;
}