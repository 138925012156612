.container-head {
  /* border-bottom: 1px solid rgb(215, 215, 215); */
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  width: 100%;
  /* background:linear-gradient(#edfbff, #eff2ff); */
  /* border-radius: 2px; */
  /* color: white; */
  /* box-shadow: px 2px 5px rgba(0, 0, 0, 0.2); */
  /* color: #7c7c7c; */
}

.container-head h6 {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  /* font-style: italic; */
  color: rgb(5, 72, 93);
  display: flex;
}

.container-form {
  /* background-color: rgb(255, 255, 255); */
  /* border: 1px solid white; */
  /* padding: 2px; */
  border-radius: 2px;
}

.container-tab {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  /* padding: 2px; */
  background: linear-gradient(to right, #bee3ff, #f7ff82);
  padding: 0;
  /* border-bottom: 1px solid rgb(215, 215, 215); */
  border-radius: 2px;
  /* border-bottom: 1px solid rgb(215, 215, 215); */
}

.tab-nav {
  display: flex;
  list-style: none;
  /* border-bottom: 0.1rem solid #ccc; */
  /* border-bottom: 1px solid rgb(215, 215, 215);
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2); */
  padding: 5px;
  margin: 0 !important;
  /* background-color: #fcfcff !important; */
  justify-content: flex-end;
}

.tab-nav li {
  text-align: center;
  font-size: 15px;
  padding: 5px 25px 5px 25px !important;
  cursor: pointer;
  border: 1px solid #42c591;
  /* background:linear-gradient(#5c6474, #D7C2EA); */
  color: #3701e9;
  border-radius: 20px;
  width: 35px;
  margin-left: 15px;
  box-shadow: 5px 0 2px rgba(11, 81, 171, 0.2);
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
}

.tab-nav li:hover {
  background-color: #f2f2f2;
}

.tab-nav li.active {
  background-color: #0160e4;
  color: white;
  border-color: #3685f4;
  /* box-shadow: 3px 0 8px rgba(38, 143, 255, 0.334); */
}

.tab-content {
  padding: 5px;
  background-color: none;
  /* max-width: 1100px; */
}


/* Table styling */
.table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
}

.table thead {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
}

.table thead th {
  text-align: left;
  padding: 10px;
  font-weight: bold;
  color: #ffffff;
}

.table tbody tr {
  border-bottom: 1px solid #dee2e6;
}

.table tbody tr:last-child {
  border-bottom: none;
}

.table tbody td {
  padding: 8px 10px;
  vertical-align: middle;
}

.table input[type="text"] {
  width: 100%;
  padding: 6px 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
}

/* Input field hover and focus */
.table input[type="text"]:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

/* Button styling */
button {
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

button.btn-success {
  background-color: #28a745;
  color: #fff;
  border: none;
}

button.btn-success:hover {
  background-color: #218838;
  box-shadow: 0 4px 6px rgba(40, 167, 69, 0.2);
}

button.btn-secondary {
  background-color: #6c757d;
  color: #fff;
  border: none;
}

button.btn-secondary:hover {
  background-color: #5a6268;
  box-shadow: 0 4px 6px rgba(108, 117, 125, 0.2);
}

/* Hide arrows for number inputs in all browsers */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .tab-content .table thead {
    display: none;
  }

  .tab-content .table tbody td {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #dee2e6;
  }

  .tab-content .table tbody tr {
    border-bottom: 2px solid #7c8ac8;
  }

  .tab-content .table tbody td::before {
    content: attr(data-label);
    font-weight: bold;
    color: #495057;
    margin-right: 10px;
  }

  .tab-content button {
    width: 100%;
    margin-bottom: 10px;
  }

  .d-flex {
    flex-direction: column;
    align-items: stretch;
  }
}